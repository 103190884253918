// src/features/appSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  url: '',
  choice: '',
  lists: [],
  extractedData: [],
  userEmail: '',
  sessionId:'',
  recordsCount:0
}

// Load state from Session Storage for this reducer
const loadStateFromSessionStorage = () => {
  const storedState = sessionStorage.getItem('webScrappingState')
  if (storedState) {
    return JSON.parse(storedState)
  }
  return initialState
}

// Helper function to save the state to Session Storage
const saveStateToSessionStorage = (state) => {
  sessionStorage.setItem('webScrappingState', JSON.stringify(state))
}

const webScrapper = createSlice({
  name: 'webScraping',
  initialState: loadStateFromSessionStorage(),
  reducers: {
    setURL: (state, action) => {
      state.url = action.payload
      saveStateToSessionStorage(state) // Save the state after the change
    },
    setChoice: (state, action) => {
      state.choice = action.payload
      saveStateToSessionStorage(state) // Save the state after the change
    },
    setParameters: (state, action) => {
      state.lists = action.payload
      saveStateToSessionStorage(state) // Save the state after the change
    },
    setExtractedData: (state, action) => {
      state.extractedData = action.payload
      saveStateToSessionStorage(state) // Save the state after the change
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload
      saveStateToSessionStorage(state) // Save the state after the change
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload
      saveStateToSessionStorage(state) // Save the state after the change
    },
    setNumberofRecords: (state, action) => {
      state.recordsCount = action.payload
      saveStateToSessionStorage(state) // Save the state after the change
    }
  }
})

export const { setURL, setChoice, setParameters, setExtractedData, setUserEmail, setSessionId, setNumberofRecords } = webScrapper.actions

export default webScrapper.reducer
